@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    color: #000;
}

/*body {*/
/*    min-height: 100vh;*/
/*}*/

/*.login {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  min-height: 100vh; */
/*  padding: 1rem 0.5rem;*/
/*}*/

/*.login-popup {*/
/*    width: 100%;*/
/*    max-width: 420px;*/
/*    min-height: 400px;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: flex-start;*/
/*    padding: 1rem;*/
/*    background-color: rgba(0,0,0,0.4);*/
/*}*/

/*form {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: space-evenly;*/
/*    flex-grow: 1;*/
/*    padding-bottom: 1rem;*/
/*}*/

/*.dashboard_title{*/
/*    color: #000;*/
/*    text-transform: uppercase;*/
/*}*/
/*.App {*/
/*    background-color: #f4f2ff;*/
/*    min-height: 100vh;*/
/*}*/
/*.navmenu .active {*/
/*    color: red;*/
/*}*/







/*a, a:visited {*/
/*    color: #fff;*/
/*}*/

/*input[type="text"],*/
/*input[type="password"],*/
/*button,*/
/*textarea {*/
/*  font-family: 'Nunito', sans-serif;*/
/*  font-size: 22px;*/
/*  padding: 0.25rem;*/
/*  border-radius: 0.5rem;*/
/*}*/

/*label,*/
/*button {*/
/*  margin-top: 1rem;*/
/*}*/

/*button {*/
/*  padding: 0.5rem;*/
/*}*/

/*li {*/
/*    list-style-type: none;*/
/*}*/

/*.instructions {*/
/*    font-size: 0.75rem;*/
/*    border-radius: 0.5rem;*/
/*    background: #000;*/
/*    color: #000;*/
/*    padding: 0.25rem;*/
/*    position: relative;*/
/*    bottom: -10px;*/
/*}*/

/*.instructions > svg {*/
/*    margin-right: 0.25rem;*/
/*}*/

/*.offscreen {*/
/*    position: absolute;*/
/*    left: -9999px;*/
/*}*/

/*.hide {*/
/*    display: none;*/
/*}*/

/*.valid {*/
/*    color: limegreen;*/
/*    margin-left: 0.25rem;*/
/*}*/

/*.invalid {*/
/*    color: red;*/
/*    margin-left: 0.25rem;*/
/*}*/

/*.errmsg {*/
/*    background-color: lightpink;*/
/*    color: firebrick;*/
/*    font-weight: bold;*/
/*    padding: 0.5rem;*/
/*    margin-bottom: 0.5rem;*/
/*}*/

/*.line {*/
/*    display: inline-block;*/
/*}*/

/*.flexGrow {*/
/*    flex-grow: 1;*/
/*    display: flex;*/
/*    justify-content: flex-start;*/
/*    align-items: flex-end;*/
/*}*/
/*input.wrong_company {*/
/*    background: red;*/
/*}*/

.indicator-progress.indicator_active{
    display: block;
}

.full_height{
    min-height: 100vh;
}
.active .menu-title{
    color: #fff!important;
}
.active .menu-item .svg-icon {
    color: #009ef7!important;
}
.active .menu-link {
    background-color: #1b1b28;
}
#kt_content {
    margin-top: 65px;
}
.ml-30{
    margin-left: 30px;
}
.mr-30{
    margin-right: 30px;
}
.text-align-r{
    text-align: right;
}
.dropdown_box{
    border: 0;
    box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 15%);
    border-radius: 0.475rem;
    padding: 1rem 0;
    background-color: #ffffff;
    position: absolute;
    left: 0;
    top: 3px;
    z-index: 999;
}
input.wrong_company {
    outline: 1px solid red;
}
/*.mr-15{*/
/*    margin-right: 15px;*/
/*}*/
.react-datepicker-wrapper {
    width: auto;
    /*margin-right: 15px;*/
}
.react-datepicker-wrapper input{
    background-color: #f5f8fa;
    color: #5e6278;
    transition: color .2s ease,background-color .2s ease;
    display: block;
    width: 100%;
    padding: .75rem 1rem;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.5;
    color: #5e6278;
    background-clip: padding-box;
    -webkit-appearance: none;
    appearance: none;
    border-radius: .475rem;
    box-shadow: none!important;
    border: none;
}

.content {
    padding: 30px!important;
}
#kt_footer {
    position: fixed;
    bottom: 0;
    width: 100%;
}
.title-404 {
    color: #A3A3C7;
}

.aside .mob_response {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    padding: 0 25px;
}
#close_mob_menu{
    display: none;
}

.custom_select {
    width: 100%;
}
.custom_select .css-1s2u09g-control {
    border-color: #f5f8fa!important;
    background: #f5f8fa!important;
}
.custom_select .css-1s2u09g-control:focus{
    border: none;
}
.custom_select .css-14el2xx-placeholder{
    font-size: 1.1rem;
}
.custom_select .select__single-value{
    font-size: 1.1rem;
    color: #5e6278;
}
.custom_select option:hover{
  background: #f5f8fa;
    font-size: 1.1rem;
}
.custom_select {
    font-size: 1.1rem!important;
}

@media (max-width: 991.98px){
    .aside .aside-logo {
        display: flex!important;
    }
    #close_mob_menu{
        display: block;
    }
    #kt_content {
        margin-top: 0;
    }
}
